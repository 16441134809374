import axios, { HttpStatusCode } from 'axios'
import router from '../router'
import { OFFLINE_ALERT_MESSAGE, FORCE_INTERNET_CONNECTION } from '../constants'
import { getCookie } from './utils'
import { useToast } from 'vuestic-ui'

const { init } = useToast()

const apiUrl = import.meta.env.VITE_API_BASE_URL

const axiosInstance = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

axiosInstance.interceptors.request.use((config) => {
  const token = getCookie('XSRF-TOKEN')
  if (token) {
    config.headers['X-XSRF-TOKEN'] = token
  }

  // Check for the browser connection status
  if (!navigator.onLine && FORCE_INTERNET_CONNECTION === 'true') {
    init({ message: OFFLINE_ALERT_MESSAGE, color: 'danger' })
    // return Promise.reject(new Error('No internet connection'));
  }
  return config
})

// apply interceptor on response
axiosInstance.interceptors.response.use((response) => response, errorResponseHandler)

function errorResponseHandler(error: any) {
  // check for errorHandle config
  // if (error.config?.hasOwnProperty('errorHandle') && error.config.errorHandle === false) {
  if (Object.prototype.hasOwnProperty.call(error.config, 'errorHandle') && error.config.errorHandle === false) {
    return Promise.reject(error)
  }

  // Automatically log out if user Unauthorized
  if (error?.response?.status === HttpStatusCode.Unauthorized) {
    init({ message: `Oops! ${error.response.data.message} 🙂`, color: 'danger' })
    return router.push('/login')
  }

  if (error?.response?.status === HttpStatusCode.InternalServerError) {
    init({ message: `Oops! Internal Server Error 🙂`, color: 'danger' })
    return router.push('/login')
  }

  if (error?.response?.status === HttpStatusCode.NotFound) {
    const errorMessage = error.response.data.message ? error.response.data.message : 'Resource Not Found!'
    init({ message: `Oops! ${errorMessage} 🙂`, color: 'danger' })
    return router.push('/login')
  }

  // if has response show the error
  if (error?.response) {
    return init({ message: `Oops! ${error.response.data.message} 🙂`, color: 'danger' })
  }

  // Check for the browser connection status
  // if (!error.response) {
  //   init({ message: "No Response, Logging you out.", color: 'danger' })
  //   return Promise.reject({
  //     message: OFFLINE_ALERT_MESSAGE+ "Logging you out.",
  //   })
  // }

  if (error) {
    init({ message: `Oops! Processing failed`, color: 'danger' })
    return router.push('/login')
  }
}

export default axiosInstance
