// Axios instance and types
import http from '../api'
import { APIPaginatedResponse, APIResponse, Filters, Pagination } from '../types'
import { getRequestParamString, parseDate } from '../utils'
import { TypeCreateTicketPayment } from './types'

const apiPath = import.meta.env.VITE_API_PATH

async function getTickets(filter?: number) {
  let categoryFilter = ''
  if (filter) {
    categoryFilter = '?category=' + filter
  }
  return await http.get<APIPaginatedResponse<any>>(apiPath + 'tickets' + categoryFilter)
}

async function getTicketOfUuid(ticketUUid: string) {
  return await http.get<APIResponse<boolean>>(`${apiPath}tickets/${ticketUUid}`)
}

async function createTicketPayment(input: TypeCreateTicketPayment) {
  const payLoad = {
    name: input.name,
    amount: input.amount,
    date: parseDate('' + input.date),
  }

  return await http.post<APIResponse<any>>(apiPath + 'tickets/payments/' + input.uuid, payLoad)
}

async function getTicketOfNo(ticketId: number) {
  return await http.get<APIPaginatedResponse<any>>(apiPath + 'tickets/no/' + ticketId)
}

async function getTicketPayments(filters?: Partial<Filters>, pagination?: Partial<Pagination>) {
  return await http.get<APIPaginatedResponse<any>>(
    apiPath + 'tickets/payments/list' + getRequestParamString(filters, pagination),
  )
}

export default {
  getTickets,
  getTicketOfUuid,
  createTicketPayment,
  getTicketOfNo,
  getTicketPayments,
}
