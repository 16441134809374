import { Filters, Pagination } from './types'
import moment from 'moment'

export const sleep = (ms = 0) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/** Validation */
export const validators = {
  email: (v: string) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return pattern.test(v) || 'Please enter a valid email address'
  },
  required: (v: any) => !!v || 'This field is required',
}

export const parseDate = (date: string, format: string = 'YYYY/MM/DD') => {
  return moment(date).format(format)
}

export const formatDate = (date: Date) => {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
}

export const parseDateToTime = (date: Date | undefined) => {
  // Parse the string into a moment object
  const momentObj = moment(date)

  // Format the time as "HH:mm"
  return momentObj.format('HH:mm')
}

export const parseTimeToDate = (timeString: string) => {
  // Parse the string into a moment object
  const momentObj = moment(timeString, 'HH:mm')
  // Convert the moment object to a JavaScript Date object
  return momentObj.toDate()
}

// Builds a parameter string for the endpoint that has searching and pagination.
export const getRequestParamString = (filters?: Partial<Filters>, pagination?: Partial<Pagination>): string => {
  let paramString = filters?.search ? `?search=${filters.search}` : ''
  paramString +=
    paramString.length > 0 && filters?.column ? getParamString(paramString, `column=${filters.column}`) : ''

  paramString += filters?.start_date
    ? getParamString(paramString, `start_date=${parseDate('' + filters.start_date)}`)
    : ''

  paramString +=
    paramString.length > 0 && filters?.end_date
      ? getParamString(paramString, `end_date=${parseDate('' + filters.end_date)}`)
      : ''

  paramString += pagination?.page ? getParamString(paramString, `page=${pagination.page}`) : ''

  paramString += pagination?.perPage ? getParamString(paramString, `per_page=${pagination.perPage}`) : ''

  return paramString
}

// Cocatenates a supplied string to the original parameter string
const getParamString = (paramString: string, param: string): string => {
  if (paramString.length <= 0) {
    return (paramString = '?' + param)
  }

  return (paramString = '&' + param)
}

// Get cookie value
export const getCookie = (name: string) => {
  const re = new RegExp(name + '=([^;]+)')
  const value = re.exec(document.cookie)
  return value != null ? unescape(value[1]) : null
}

export const findInArray = (arrayObj: Array<any>, identifierParam: string, valueParam: string) =>
  arrayObj.find((item) => item[identifierParam] === valueParam)

export const getNestedObjectValue = (obj: any, key: string) => {
  return key.split('.').reduce((o, k) => (o || {})[k], obj)
}

export const getSearchColumnKey = (key: string) => {
  const splitKey = key.split('.')
  return splitKey.length > 1 ? splitKey[1] : key
}

export const setPagination = (pagination: Pagination) => {
  return {
    page: pagination.meta?.current_page ? pagination.meta?.current_page : pagination.page,
    perPage: pagination.meta?.per_page ? pagination.meta?.per_page : pagination.perPage,
    total: pagination.meta?.total ? pagination.meta?.total : pagination.total,
  }
}

export const printReceipt = (receiptElement: any) => {
  // const receipt = this.$refs.receipt.$el; // Get the receipt element
  const printWindow = window.open('', '_blank')
  printWindow?.document.write(`
    <html>
    <head>
      <title>Print Receipt</title>
      <style>
        @page { size: 80mm auto; margin: 0; }
        body { margin: 0; font-family: monospace; }
      </style>
    </head>
    <body>${receiptElement}</body>
    </html>
  `)
  printWindow?.document.close()
  printWindow?.focus()
  printWindow?.print()
  printWindow?.close()
}
