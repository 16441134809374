import clientsController from './clients'
import userController from './user'
import itemController from './items'
import itemGroupController from './itemGroup'
import itemCategoryController from './inventory/itemCategory'
import itemTypeController from './inventory/itemType'
import unitController from './inventory/units'
import menuController from './restaurant/menu'
import menuCategoryController from './restaurant/menuCategory'
import orderController from './restaurant/order'
import roomController from './rooms'
import roomTableController from './roomTable'
import ticketController from './tickets'

export const API = {
  clients: clientsController,
  user: userController,
  items: itemController,
  itemGroup: itemGroupController,
  itemCategory: itemCategoryController,
  itemType: itemTypeController,
  unit: unitController,
  menu: menuController,
  menuCategory: menuCategoryController,
  order: orderController,
  room: roomController,
  roomTable: roomTableController,
  ticket: ticketController,
}
